.FooterWrapper {
  background-color: var(--black);
  width: 100%;
  height: 350px;
  color: #ffff;
  border-left: 6px solid #3cc;
  padding: 16px 16px 16px 10px;
}

.FooterWrapper a,
.FooterWrapper div,
.FooterWrapper input {
  color: #ffff;
  font-family: Atype-Regular;
  font-size: 18px;
}
.Button {
  cursor: pointer;
  height: 29px;
  width: 83px;
  border: 0px;
  border-radius: 0px;
  font-family: General Sans Variable;
  font-size: 15px;
  font-style: normal;
  font-weight: 520;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}

.SupportUs {
  margin-top: 24px;
  margin-bottom: 24px;
  font-family: Atype-Regular;
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 407px;
}
.inputContainer {
  width: calc(100% - 87px);
  gap: 12px;
  display: flex;
  flex-direction: column;
}
.searchInput {
  background-color: var(--black);
  border: 0px;
  background: none;
  width: calc(100% - 90px);
  border-bottom: 1px solid #cccc;
}
.searchInput:focus {
  outline: 1px solid #3cc;
}
.FooterContent {
  display: flex;
  flex-direction: column;
}
@media (min-width: 769px) and (max-width: 1024px) {
  .FooterContent {
    display: flex;
    flex-direction: row;
    gap: 40px;
  }
  .Links {
    flex: 1;
  }
  .FooterWrapper {
    margin-top: 32px;
    padding: 40px;
  }
  .FooterWrapper .SupportUs {
    max-width: 407px;
  }

  .FooterWrapper div,
  .FooterWrapper input {
    font-family: Atype-Medium;
    font-size: 19px;
  }
}

@media (min-width: 1025px) {
  .FooterContent {
    display: flex;
    flex-direction: row;
    gap: 40px;
  }
  .Links {
    flex: 1;
  }
  .FooterWrapper {
    /* margin-top: 32px; */
    padding: 40px 160px;
  }
  .FooterWrapper .SupportUs {
    max-width: 407px;
  }
  .FooterWrapper div,
  .FooterWrapper input {
    font-family: Atype-Medium;
    font-size: 26px;
  }
}
