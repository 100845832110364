html{
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --menu-height: 80px;
  --menu-height-desktop: 80px;
  --light-gray: #a2a2a2;
  --dark-gray: #646464;
  --black: #000000;
  --green: #00F982;
  --background: #eff3fe;
}

a {
  text-decoration: none;
  color: #000;
}

hr {
  height: 0;
  border-top-width: 1px;
  border-style: solid none none none;
  color: #ccc;
  margin: 0;
}

.menu-placeholder {
  height: var(--menu-height);
}

.menu-placeholder-desktop {
  height: var(--menu-height-desktop);
}

* {
  overscroll-behavior-y: none;
  box-sizing: border-box;
}

.data-message-mobile {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #646464;
}

.data-message {
  /* font-family: General Sans; */
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  /* line-height: 19px; */
  color: #646464;
  margin-bottom: 8px;
}

.strong-text-message-mobile {
  font-family: Atype-Medium;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 4px;
  color: #000;
}

.strong-text-message {
  font-family: Atype-Medium;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 12px;
  margin-top: 4px;
  color: #000;
}

.cousine-font {
  font-family: Cousine, monospace;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}

.circle-topic {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
  margin-top: 2px;
  display: inline-block;
}

strong {
  font-weight: 600;
}

.italic-cousine {
  font-family: Cousine, monospace;
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
  line-height: 10px;
}

.sign-facebook {
  margin-top: 16px;
  font-size: 14px;
}
