.CardPromise {
  height: calc(100vh - var(--menu-height));
  margin-top: var(--menu-height);
  padding: 2px;
}

.CardBody {
  padding-top: 18px;
  padding-left: 10px;
  padding-right: 12px;
  border-radius: 12px;
  background-color: #fff;
  height: calc(100vh - var(--menu-height) - 4px);
  display: flex;
}

.BlockLeftRotate {
  padding-right: 10px;
  margin-right: 12px;
  height: calc(100% - 97px);
  border-right: 1px solid #e4e4e7;
}

.CloseDetail {
  width: 26px;
  height: 26px;
  background-color: #eff3fe;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.NumberBlock {
  width: 26px;
  margin-bottom: 56px;
  height: calc(100% - 80px);
  padding-top: 50px;
  background-color: #eff3fe;
  border-radius: 13px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: center;
}

.BorderLine {
  width: 1px;
  background-color: #646464;
  height: 125px;
  margin-top: 20px;
}

.NumberPromise {
  font-family: Cousine, monospace;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  width: 171px;
  line-height: 18px;
  text-align: right;
  color: #000000;
  transform: rotate(-90deg);
}

.DatePromise {
  font-family: Cousine, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #646464;
  padding-left: 30px;
  transform: rotate(-90deg);
}

.TitlePromise {
  font-family: Atype-Medium;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 16px;
  margin-top: 3px;
  width: 90%;
}

.ImagePromise {
  object-position: center center;
  overflow-y: hidden;
  max-height: 30%;
  margin-top: 18px;
  margin-bottom: 18px;
  border-radius: 10px;
}

.SensitiveContent {
  background: #eff3fe;
  border-radius: 2px;
  width: 100%;
  height: 221px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
}

.ViewButton {
  background: #6e7b9e;
  opacity: 0.5;
  border-radius: 32px;
  color: #fff;
  margin-top: 10px;
  text-align: center;
  padding: 10px;
  text-transform: uppercase;
}

.ImageRadius {
  border-radius: 10px;
  object-fit: cover;
}

.topicBox {
  margin-bottom: 16px;
}

.labelTopic {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 550;
  font-size: 13px;
  line-height: 19px;
  text-transform: uppercase;
  margin-bottom: 6px;
}

.TopicName {
  font-family: Cousine, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
}

.EventList {
  margin-top: 8px;
}

.EventLabel {
  font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #646464;
  margin-top: 16px;
}

.EventContainer {
  display: flex;
  align-items: center;
}

.CircleEvent {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
  display: flex;
  align-self: flex-start;
  margin-top: 2px;
}

.NameEvent {
  font-family: Cousine, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 2px;
}

.ListPromises {
  display: flex;
  max-width: 100vw;
  margin-top: 20px;
  position: fixed;
  bottom: 0px;
  justify-content: flex-start;
  overflow-x: auto;
  flex-direction: row;
  flex-wrap: nowrap;
  z-index: 1;
}

.IconTopic {
  margin-right: 10px;
}

.SwipeHint {
  position: absolute;
  border-top: 1px solid #e4e4e7;
  margin: 0px 10px;
  width: calc(100% - 22px);
  color: var(--dark-gray);
  bottom: 56px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 11px 16px 11px 16px;
}
