.part {
  padding-left: 18px;
  margin-bottom: 60px;
  width: max-content;
}

.section {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 450;
  font-size: 13px;
  line-height: 19px;
  text-transform: uppercase;
  margin: 0;
}

.title {
  font-family: Atype-Medium;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  color: #000;
}

.textContainer {
  padding: 0 18px;
}

.narrativeText {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  padding-top: 50px;
  color: var(--black);
}

.narrativeCaption {
  font-family: Cousine, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 25px;
  padding-top: 8px;
  margin: 0;
  color: var(--black);
}

.narrativeCaptionSource {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  margin: 0;
  padding-top: 10px;
  color: var(--black);
}

/* .IntroStrong {
  font-weight: 600;
}

.intro b {
  font-weight: 600;
} */

.wall {
  position: relative;
  height: 100vh;
}

.quote {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 0 26px;
}
