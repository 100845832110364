.Post {
  display: flex;
  padding-bottom: 30px;
  border-bottom: 1px solid #ccc;
  flex-direction: row;
  max-height: 261px;
  overflow-y: hidden;
}

.imgContainer {
  height: 230px;
  width: 410px;
}

.TextBlockPost {
  margin-left: 30px;
  width: 50%;
  max-height: 230px;
  overflow-y: hidden;
}

.FacebookLabel {
  font-family: Cousine, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  text-transform: uppercase;
}

.DatePost {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 110%;
  margin-top: 5px;
  margin-bottom: 12px;
  letter-spacing: 0;
}

.TitlePost {
  font-family: Atype-Medium;
  font-style: normal;
  font-weight: normal;
  font-size: 31px;
  line-height: 130%;
  margin-top: 12px;
}

.TitlePost:hover {
  color: #3cc;
  cursor: pointer;
}

.DescriptionPost {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28.8px;
  margin-top: 12px;
}

@media (max-width: 768px) {
  .imgContainer {
    height: 105px;
    width: 120px;
    margin-left: 10px;
  }

  .Post {
    padding: 16px 16px 32px 16px;
    flex-direction: row-reverse;
  }

  .TextBlockPost {
    margin-left: 0px;
    width: 100%;
  }

  .LastPost {
    display: flex;
    margin-bottom: 30px;
  }

  .FacebookLabel {
    font-size: 13px;
    line-height: 17px;
  }

  .DatePost {
    font-size: 13px;
    line-height: 110%;
    margin-top: 4px;
  }

  .TitlePost {
    font-size: 18px;
    line-height: 130%;
    margin-top: 16px;
  }
}
