.glitchMaster {
  position: fixed;
  top: 44px;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

.glitchMaster div {
  opacity: 0;
}

.introMessageContainer {
  position: absolute;
  top: 120%;
  left: 50%;
  transform: translate(-50%, -150%);
  z-index: 20000;
  border-radius: 8px;
  padding: 20px;
  margin: auto;
  width: calc(100% - 24px);
  max-width: 630px;
  background-color: white;
  box-shadow: 0 0 10px rgb(0 0 0 / 60%);
  animation: fadeIn 1.5s;
  animation-play-state: paused;
}

.introMessageContainerOut {
  position: absolute;
  top: 140%;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 20000;
  border-radius: 8px;
  padding: 20px;
  margin: auto;
  width: calc(100% - 24px);
  max-width: 630px;
  background-color: white;
  box-shadow: 0 0 10px rgb(0 0 0 / 60%);
  animation: fadeOut 1s forwards linear;
  animation-play-state: paused;
}

.introTitle {
  font-family: General Sans Variable;
  font-weight: 500;
  font-size: 24px;
  margin-top: 32px;
}

.introTitleMobile {
  font-family: General Sans Variable;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  margin-top: 32px;
}

.introDescription {
  font-family: General Sans Variable;
  font-weight: 450;
  font-size: 18px;
  width: 580px;
  margin-top: 20px;
}

.introDescriptionMobile {
  font-family: General Sans Variable;
  font-weight: 450;
  font-size: 18px;
  line-height: 24px;
  margin-top: 20px;
}

.introCTA {
  font-family: General Sans Variable;
  font-weight: 450;
  font-size: 14px;
  text-align: center;
  margin-top: 44px;
}

.introCTAMobile {
  font-family: General Sans Variable;
  font-weight: 450;
  font-size: 14px;
  text-align: left;
  margin-top: 44px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    top: 140%;
  }
  100% {
    opacity: 1;
    top: 120%;
  }
}

@keyframes fadeOut {
  0% {
    top: 120%;
  }
  100% {
    top: 100%;
  }
}
