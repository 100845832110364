.wall {
  overflow: hidden;
  height: calc(100vh - var(--menu-height));
  background-color: var(--background);
  position: relative;
  pointer-events: none;
}

.messages {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 18px 20px 20px;
  pointer-events: none;
}

.scrollArrow {
  font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: #646464;
}

.arrowDiv {
  width: 1px;
  background-color: #646464;
  align-self: center;
  margin-top: 10px;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.arrowEdge {
  width: 5px;
  height: 5px;
  border-color: #646464;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: solid;
  border-left-style: solid;
  border-width: 1px;
  transform: rotate(-45deg);
}

.yearSwatchContainer {
  position: absolute;
  top: 9px;
  left: 10px;
  background: rgba(239, 243, 254, 0.9);
  border: 1px solid #eff3fe;
  box-sizing: border-box;
  border-radius: 40px;
  color: #000;
  width: 80px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.yearSwatchLabel {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  text-align: center;
  margin-bottom: -5px;
}

.yearSwatch {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 32px;
  text-align: center;
}

.numberTilesContainer {
  position: absolute;
  top: 9px;
  right: 10px;
  background: rgba(239, 243, 254, 0.9);
  border: 1px solid #eff3fe;
  box-sizing: border-box;
  border-radius: 40px;
  color: #000;
  width: 100px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.numberTilesLabel {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  text-align: center;
  margin-bottom: -5px;
}

.numberTiles {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 550;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}

.iconLegend {
  position: absolute;
  top: 9px;
  left: 10px;
  pointer-events: all;
  z-index: 2;
}

.audios {
  overflow: hidden;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  padding-right: 18px;
  padding-left: 18px;
  width: 100vw;
  pointer-events: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.audiosAndMessage {
  display: flex;
  width: 100%;
  align-items: flex-end;
  flex-direction: column;
}

.audiosInner::before {
  content: ' ';
  display: block;
  width: 9px;
  flex: 0 0 9px;
}

.audiosInner::after {
  content: ' ';
  display: block;
  width: 9px;
  flex: 0 0 9px;
}

.audio {
  background: #485475;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: absolute;
  width: 260px;
  padding: 15px 18px;
  border-bottom-left-radius: 0;
  margin-right: calc((100vw - 260px) - 36px);
  margin-top: 70px;
}

.iconDetail {
  position: absolute;
  top: var(--menu-height);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  z-index: 1;
}

.buttonDetail {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
  border-radius: 28px;
  width: 150px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonLegend {
  position: absolute;
  top: 12px;
  left: 0px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 40px;
  color: #000;
  width: 130px;
  height: 40px;
  z-index: 2;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.messageSample {
  width: 80px;
  height: 80px;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 30px;
  left: 30px;
  border-radius: 40px;
}

.audioHeader {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.audioTrack {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 10px 0;
}

.audioFooter {
  font-family: Cousine, monospace;
  font-style: italic;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.audioDuration {
  font-family: General Sans Variable;
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  font-feature-settings: 'salt' on;
}
.audioYear {
  font-family: General Sans Variable;
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-feature-settings: 'salt' on;
}

.part {
  padding-left: 18px;
  margin-top: 30px;
  width: max-content;
}

.section {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 450;
  font-size: 13px;
  line-height: 19px;
  text-transform: uppercase;
  margin: 0;
}

.title {
  font-family: Atype-Medium;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  color: #000;
}
