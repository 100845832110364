.Legend {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.LegendMobile {
  position: fixed;
  top: calc(100vh + var(--menu-height));
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: calc(100vh - var(--menu-height));
  background-color: #fff;
  padding-left: 9px;
  padding-right: 18px;
  padding-top: 10px;
  overflow: auto;
  z-index: 9;
  display: flex;
  pointer-events: initial;
}


.ContentBox {
  margin-left: 8px;
}


.ItemLegend {
  display: flex;
  align-items: top;
  
  margin-bottom: 4px;
  min-width: 180px;
}

.RectLegend {
  width: 8px;
  height: 20px;
  margin-right: 11px;
}

.RectLegendMobile {
  margin-right: 11px;
  width: 8px;
  height: 20px;
}

.NameItemLegend {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
}
