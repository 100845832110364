@font-face {
  font-family: 'Cousine';
  src: url('./Cousine-Regular.ttf') format('truetype');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Cousine';
  src: url('./Cousine-Italic.ttf') format('truetype');
  font-weight: normal;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Cousine';
  src: url('./Cousine-Bold.ttf') format('truetype');
  font-weight: bold;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Cousine';
  src: url('./Cousine-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-display: swap;
  font-style: italic;
}
