.wall {
  position: relative;
  height: calc(100vh - 80px);
}

.countSwatch {
  font-family: General Sans Variable;
  background: rgba(239, 243, 254, 0.8);
  border: 1px solid #eff3fe;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 40px;
  position: absolute;
  box-sizing: border-box;
  border-radius: 40px;
  color: #000;
  width: 110px;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.numberTilesLabel {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  text-align: center;
  margin-bottom: -5px;
}

.numberTiles {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 550;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
}

.backgroundUniform {
  position: absolute;
  top: var(--menu-height-desktop);
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0085ff;
}

.responses {
  position: absolute;
  top: var(--menu-height-desktop);
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  z-index: 2;
}

.responsesRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1 1 0%;
}
