.Legend {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 375px;
  background-color: #fff;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  overflow: auto;
  z-index: 1;
}

.LegendMobile {
  position: fixed;
  top: calc(100vh + var(--menu-height));
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: calc(100vh - var(--menu-height));
  background-color: #fff;
  padding-left: 9px;
  padding-right: 18px;
  padding-top: 10px;
  overflow: auto;
  z-index: 9;
  display: flex;
  pointer-events: initial;
}

.TopLegend {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 8px;
  margin-left: -9px;
}

.HowToRead {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  background: #eff3fe;
  border-radius: 53px;
  padding: 6px 12px;
  width: 66%;
}

.ContentBox {
  margin-left: 8px;
}

.Shape {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 550;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 16px;
  margin-top: 8px;
}

.ShapeMobile {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 16px;
  margin-top: 6px;
}

.ItemLegend {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.RectLegend {
  width: 8px;
  height: 20px;
  margin-right: 11px;
}

.RectLegendMobile {
  margin-right: 11px;
  width: 8px;
  height: 20px;
}

.NameItemLegend {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
}

.InfoText {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  margin-top: 12px;
  margin-bottom: 12px;
}

.InfoTextMobile {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #000;
  margin-top: 12px;
  margin-bottom: 12px;
  z-index: 99;
}

.CloseLegend {
  pointer-events: all;
  margin-right: 10px;
}

.TitleLegendMobile {
  width: 32px;
  height: 85%;
  background: #eff3fe;
  border-radius: 53px;
  margin-left: 4px;
  position: relative;
  display: block;
}

.Methodology {
  font-weight: 600;
  text-decoration-line: underline;
  color: #3cc;
  cursor: pointer;
  pointer-events: all;
}

.HowToReadMobile {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  transform: rotate(-90deg);
  transform-origin: 0 0;
  position: absolute;
  top: 160px;
  left: 0;
  width: 150px;
}
