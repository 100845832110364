.wall {
  position: relative;
  height: calc(100vh - var(--menu-height));
}

.countSwatchContainer {
  position: absolute;
  top: 9px;
  right: 10px;
  background: rgba(239, 243, 254, 0.8);
  border: 1px solid #eff3fe;
  box-sizing: border-box;
  border-radius: 40px;
  color: #000;
  width: 100px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.countSwatchLabel {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  text-align: center;
  margin-bottom: -5px;
}

.countSwatch {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 550;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}

.backgroundUniform {
  position: absolute;
  top: var(--menu-height);
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0085ff;
}

.responses {
  position: absolute;
  top: var(--menu-height);
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  z-index: 2;
}

.responsesRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1 1 0%;
}
