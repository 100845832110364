.Message {
  background: #ffffff;
  border-radius: 8px 8px 1px 8px;
  padding: 12px 16px;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 12px;
  font-family: General Sans Variable;
}

.MessageWithBoxShadow {
  background: #fff;
  border-radius: 8px 8px 1px 8px;
  padding: 12px 16px;
  font-weight: 500;
  margin-bottom: 12px;
  font-size: 16px;
  font-family: General Sans Variable;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
}

.MessagePost {
  border-radius: 10px 10px 1px 10px;
  margin-bottom: 12px;
  padding: 2px;
  z-index: 99;
}

.MessagePostBody {
  background-color: #fff;
  padding: 12px 16px;
  border-radius: 8px;
  z-index: 100;
}

.SensitiveContent {
  background: #eff3fe;
  border-radius: 2px;
  width: 100%;
  height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
}

.ViewButton {
  margin-top: 12px;
  background: #6e7b9e;
  opacity: 0.5;
  border-radius: 32px;
  color: #fff;
  text-align: center;
  font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  z-index: 100;
  pointer-events: all;
  cursor: pointer;
}
