.Repository {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 36px;
  padding-bottom: 1px;
  margin-top: 100px;
  margin-bottom: 100px;
}

.StatementsFlex {
  display: flex;
  justify-content: space-between;
}

.StatementLabel {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 34px;
  width: 670px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}

.ExamplesPromises {
  display: flex;
  justify-content: center;
}

.Promise {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
  width: 371px;
  padding: 2px;
  margin-left: 20px;
}

.BlockLeftRotate {
  padding-right: 10px;
  margin-right: 12px;
  border-right: 1px solid #e4e4e7;
}

.PromiseBody {
  background-color: #fff;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
}

.NumberBlock {
  width: 26px;
  height: 390px;
  padding-top: 36px;
  background-color: #eff3fe;
  border-radius: 13px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: center;
}

.BorderLine {
  width: 1px;
  background-color: #646464;
  height: 125px;
  margin-top: 20px;
}

.NumberPromise {
  font-family: Cousine, monospace;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  width: max-content;
  color: #000000;
  transform: rotate(-90deg);
}

.DatePromise {
  font-family: Cousine, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #646464;
  padding-left: 30px;
  transform: rotate(-90deg);
}

.Topic {
  margin-top: 12px;
}

.labelTopic {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 550;
  font-size: 13px;
  line-height: 19px;
  text-transform: uppercase;
  margin: 0 0 6px 0;
}

.TopicName {
  font-family: Cousine, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
}

.IconViolence {
  margin-right: 5px;
}

.Event {
  margin-top: 16px;
}

.ChipEventHate {
  border-radius: 20px;
  width: max-content;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 3px;
  padding-bottom: 2px;
  font-family: Cousine, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  margin-right: 2px;
  margin-bottom: 4px;
  background-color: #00f982;
  margin-top: 10px;
  margin-bottom: 8px;
}

.ChipEvent {
  border-radius: 20px;
  width: max-content;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 3px;
  padding-bottom: 2px;
  font-family: Cousine, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  margin-right: 2px;
  margin-bottom: 4px;
  background-color: #0085ff;
  margin-top: 10px;
  margin-bottom: 30px;
}

.TitlePromise {
  font-family: Atype-Medium;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 16px;
}

.BlockBlack {
  background-color: #010f22;
  padding: 16px;
  width: 100%;
  margin-top: 16px;
  border-radius: 15px;
}

.Citation {
  font-family: Cousine, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #fff;
  cursor: pointer;
}

.CitationStrong {
  font-weight: bold;
}

.CitationAuthor {
  font-size: 14px;
  margin-top: 20px;
  font-weight: normal;
}

.ContinueReading {
  font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  text-decoration: underline;
  margin-top: 32px;
  cursor: pointer;
}

.ContinueReading:hover {
  color: #3cc;
}

.GrayCitation {
  color: gray;
}

.SignatureCitation {
  font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  margin-top: 14px;
}

.SliderPromises {
  margin-top: 40px;
  margin-bottom: 30px;
  overflow-x: auto;
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  flex-direction: row;
  flex-wrap: nowrap;
}

.SlidePromise {
  height: 381px;
  width: 23vw;
  background: #010f22;
  border-radius: 20px;
  color: #fff;
  padding-top: 34px;
  padding-left: 16px;
  padding-right: 16px;
}

.TitleStatement {
  font-family: Cousine, monospace;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 29px;
  height: 50%;
}

.FinalSlogan {
  margin-top: 100px;
  padding-left: 24px;
  padding-right: 24px;
}

.RelatedArticlesTitle {
  font-family: Atype-Medium;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  margin-block-end: 28px;
}

.RelatedArticles {
  margin-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1310px;
  margin-left: auto;
  margin-right: auto;
}

.Posts {
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.NumberOccurence {
  margin-top: 20px;
  font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #a2a2a2;
}

.TilesListStatement {
  display: flex;
  margin-top: 17px;
}

.EventTypeLabel {
  margin-bottom: 8px;
}

.EventContainer {
  display: flex;
  align-items: center;
}

.CircleEvent {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
  display: flex;
  align-self: flex-start;
  margin-top: 2px;
}

.NameEvent {
  font-family: Cousine, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 2px;
}

.NavigationStatement {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
}

.ItemNavigation {
  width: 4px;
  height: 12px;
  margin-right: 4px;
  background: #a2a2a2;
}

.ItemNavigationActive {
  background: #000;
  width: 4px;
  height: 12px;
  margin-right: 4px;
  margin-top: -6px;
}

.YearStatement {
  margin-top: 4px;
  font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.BlockYear {
  margin-right: 12px;
}

.StatementsList {
  display: flex;
}

.CloseCard {
  cursor: pointer;
  width: 26px;
  height: 26px;
  background-color: #eff3fe;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
