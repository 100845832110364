.video {
  position: relative;
  height: calc(100% - 106px);
  width: 100%;
  flex: 1;
  object-fit: cover;
  object-position: center bottom;
}

.sectionContainer{
  height: '100vh';
  min-height: '100vh';
  max-height: '-webkit-fill-available';
  background-image: url(../../assets/wall-background.svg);
  position: relative;
}

.scrollDown {
  position: absolute;
  bottom: 0;
  padding: 20px;
  height: 60px;
  background-color: #eff3fee5;
  width: 100%;
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  gap: 8px;
  justify-content: center;
  border-top: 1px solid #e2e2e2;
}


.fbLikeCardWarp {
  position: relative;
  overflow: hidden;
  margin: 12px;
  padding: 16px;
  background-color: white;
  border-radius: 12px;
}
.skipButton {
  border-radius: 10px;
  padding: 10px 20px;
  border: 0px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.iconFooter {
  margin-right: 5px;
}

.landingTitle {
  font-family: General Sans Variable;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  line-height: 26px;
  margin-top: 32px;
}

.landingDescription {
  font-family: General Sans Variable;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  margin-top: 32px;
}