.wall {
  overflow: hidden;
  height: calc(100vh - var(--menu-height-desktop));
  background-color: var(--background);
  position: relative;
}

.part {
  padding-left: 18px;
  margin-top: 30px;
  width: max-content;
}

.section {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 450;
  font-size: 13px;
  line-height: 19px;
  text-transform: uppercase;
  margin: 0;
}

.title {
  font-family: Atype-Medium;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  color: #000;
}

.messages {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  position: absolute;
  width: 375px;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 18px 20px 20px;
  pointer-events: none;
}

.scrollArrow {
  font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: #646464;
}

.arrowDiv {
  width: 1px;
  background-color: #646464;
  align-self: center;
  margin-top: 10px;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.arrowEdge {
  width: 5px;
  height: 5px;
  border-color: #646464;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: solid;
  border-left-style: solid;
  border-width: 1px;
  transform: rotate(-45deg);
}

.yearSwatchContainer {
  box-sizing: border-box;
  border-radius: 40px;
  color: #000;
  width: 80px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.yearSwatchLabel {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  text-align: center;
  margin-bottom: -5px;
}

.yearSwatch {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 32px;
  text-align: center;
}

.InfoPill {
  background: rgba(250, 251, 255, 0.7);
  height: 54px;
  position: absolute;
  right: calc(100vw - 355px);
  top: 12px;
  width: 318px;
  border: 1px solid #fafbff;
  box-sizing: border-box;
  border-radius: 40px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.numberTilesContainer {
  box-sizing: border-box;
  border-radius: 40px;
  color: #000;
  width: 100px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.numberTilesLabel {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  text-align: center;
  margin-bottom: -5px;
}

.numberTiles {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 550;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
}

.iconLegend {
  position: absolute;
  top: 9px;
  left: 10px;
  pointer-events: all;
}

.audios {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 375px;
  pointer-events: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 100vh;
  bottom: 0;
}

.audiosInner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding-left: 18px;
  padding-right: 12px;
  flex-wrap: nowrap;
}

.audio {
  background: #485475;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 18px;
  width: 290px;
  padding: 15px 18px;
  margin-right: 60px;
  border-bottom-left-radius: 0;
  z-index: 1;
  pointer-events: all;
}

.iconDetail {
  position: absolute;
  bottom: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: all;
}

.buttonDetail {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 28px;
  width: 170px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.messageSample {
  width: 94px;
  height: 94px;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 30px;
  left: 30px;
  border-radius: 30px;
  pointer-events: none;
  z-index: 1;
}

.messagePost {
  pointer-events: none;
}

.audioHeader {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.audioTrack {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 12px 0;
}

.audioFooter {
  font-family: Cousine, monospace;
  font-style: italic;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.audioYear {
  font-family: General Sans Variable;
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-feature-settings: 'salt' on;
}

.audioDuration {
  font-family: General Sans Variable;
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  font-feature-settings: 'salt' on;
}

.overlay {
  position: fixed;
  top: 80px;
  bottom: 0;
  width: calc(100vw - 375px);
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  pointer-events: none;
}

.overlayMessage {
  background-color: white;
  padding: 16px 20px;
  border-radius: 8px;
  position: absolute;
  width: max-content;
  display: inline-block;
  font-family: General Sans Variable;
  font-weight: 500;
  font-size: 16px;
}

.overlay svg {
  position: absolute;
  top: 576px;
  left: 769px;
}
