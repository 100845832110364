.sectionContainer{
  height: '100vh';
  background-image: url(../../assets/wall-background.svg);
  position: 'relative';
}

.video {
  width: 100%;
  height: calc(100% - 136px);
  overflow: hidden;
  object-fit: contain;
  object-position: center center;
  background-color: black;
}

.fbLikeCardWarp {
  overflow: hidden;
  height: calc(100vh - var(--menu-height-desktop));
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

.scrollDown {
  position: absolute;
  top: calc(100vh - 106px);
  padding: 20px;
  height: 106px;
  background-color: #eff3fee5;
  width: 100%;
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 8px;
  justify-content: center;
  border-top: 1px solid #e2e2e2;

}


.fbMockLeft {
  width: 20vw;
}

.fbMockRight {
  width: 20vw;
}

.fbMockCenter {
  height: calc(100% - 106px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60vw;
}

.fbMockTop {
  height: 27px;
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.fbMockLeftContent {
  background: transparent;
  width: calc(100% - 28px);
  height: calc(100vh - var(--menu-height-desktop));
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.fbMockRightContent {
  background: transparent;
  margin-left: 28px;
  height: calc(100vh - var(--menu-height-desktop));
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.imageLanding{
  width: 100%;
}

.SkeletonItem {
  margin-left: 20px;
  padding-top: 30px;
  display: flex;
  align-items: center;
}

.SkeletonEllipse {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #eff3fe;
}

.SkeletonRectangle {
  width: 119px;
  height: 20px;
  border-radius: 10px;
  background: #eff3fe;
  margin-left: 16px;
}

.fbLikeCard {
  background-color: #ffffff;
  overflow: hidden;
  display: flex;
  width: auto;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transform-origin: center center;
  border-radius: 10px;
  position: relative;
}

.landingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landingTitle {
  font-family: General Sans Variable;
  font-weight: 500;
  font-size: 24px;
  text-align: center;

}

.landingTitleMobile {
  font-family: General Sans Variable;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  margin-top: 32px;
}

.landingDescription {
  font-family: General Sans Variable;
  font-weight: 450;
  font-size: 14px;
  max-width: 500px;
  margin-top: 24px;
  text-align: center;
}

.landingDescriptionMobile {
  font-family: General Sans Variable;
  font-weight: 450;
  font-size: 18px;
  line-height: 24px;
  margin-top: 20px;
}