@font-face {
  font-family: 'Atype-Regular';
  src: url('./AtypBLDisplay-Regular.otf') format('opentype');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Atype-Medium';
  src: url('./AtypBLDisplay-Medium.otf') format('opentype');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}
