.link {
  color: #3cc;
  text-decoration: underline;
  word-break: break-word;
}

.About {
  margin-top: var(--menu-height-desktop);
  font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
}

.ItemAccordion {
  width: 100vw;
  border-bottom: 1px solid #ccc;
}

.TitleAccordion {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 39px;
  cursor: pointer;
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  color: var(--black);
}

.Signature {
  padding-left: 39px;
  padding-top: 25px;
}

.SignatureLast {
  padding-left: 39px;
  padding-top: 25px;
  padding-bottom: 34px;
}

.LabelSignature {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}

.SignatureStrong {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 550;
  font-size: 15px;
  line-height: 18px;
}

.ItemExpanded {
  padding-left: 39px;
  padding-right: 18px;
  overflow: hidden;
  transition: max-height 500ms ease-in-out;
}

.ItemExpanded p {
  max-width: 620px;
}

.TableMethodology td {
  border-bottom: 1px solid #ccc;
}

.TableMethodology {
  max-width: max-content;
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.TableColorTile {
  width: 12px;
  height: 36px;
  display: block;
}

td {
  vertical-align: top;
}

.TableTitle {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 550;
  font-size: 18px;
}

.TableTitle td {
  padding-bottom: 12px;
  padding-right: 24px;
}

.TableTextCell {
  padding-top: 4px;
  padding-bottom: 4px;
}
