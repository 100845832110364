.link {
  color: #3cc;
  text-decoration: underline;
  word-break: break-word;
}

.About {
  margin-top: var(--menu-height);
  font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

.ItemAccordion {
  width: 100vw;
  border-bottom: 1px solid #ccc;
}

.TitleAccordion {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 18px;
  cursor: pointer;
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  color: var(--black);
}

.Signature {
  padding-left: 18px;
  padding-top: 18px;
}

.SignatureSecond {
  padding-left: 18px;
  padding-top: 18px;
  padding-bottom: 30px;
}

.LabelSignature {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}

.SignatureStrong {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 550;
  font-size: 15px;
  line-height: 18px;
}

.ItemExpanded {
  padding-left: 18px;
  padding-right: 18px;
}

.TableMethodology td {
  border-bottom: 1px solid #ccc;
}

.TableMethodology {
  max-width: max-content;
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 40px;
}

.TableColorTile {
  width: 12px;
  height: 36px;
  display: block;
}

td {
  vertical-align: top;
}

.TableTitle {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
}

.TableTitle td {
  padding-bottom: 12px;
  padding-right: 12px;
}

.TableTextCell {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 4px;
}
