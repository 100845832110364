
.hamburgerIconWrapper {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.hamburgerIconWrapper div {
  position: relative;
  display: flex;
  padding: space-m-p(0.5) 0;
  width: 36px;
  height: 8px;
}
.hamburgerIconWrapper div div {
  position: absolute;
  left: 0;
  transition-duration: 0.6s;
}

.hamburgerIconWrapper:hover div:first-child div {
  left: 20%;
  transition-duration: 0.6s;
}
.hamburgerIconWrapper:hover div:last-child div {
  left: -20%;
  transition-duration: 0.6s;
}
