.TopBarMobile {
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 16px;
  padding-right: 16px;
  height: var(--menu-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  right: 0;
  background: #eff3fe;
  border-bottom: 1px solid #e2e2e2;
}

.TitleMobile {
  font-family: Cousine, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  margin-right: 15px;
  padding-top: 2px;
}

.MenuMobileOpen {
  position: absolute;
  right: 0px;
  top: calc(var(--menu-height) - 1px);
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: calc(100vh - var(--menu-height));
  font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

.BodyMenuMobile {
  background-color: #fff;
  height: max-content;
  padding-bottom: 24px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.MenuDesktopOpen {
  position: absolute;
  right: 0px;
  top: var(--menu-height-desktop);
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: calc(100vh - var(--menu-height-desktop));
  font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

.BodyMenuDesktop {
  width: 530px;
  background-color: #fff;
  padding-top: 12px;
  height: calc(100vh - var(--menu-height-desktop));
  margin-left: calc(100% - 530px);
}

.CloseMenu {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.ItemMenu {
  font-family: Atype-Regular;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.ItemMenuSup {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 450;
  font-size: 13px;
  line-height: 19px;
  text-transform: uppercase;
  margin: 0;
}

.ItemMenuTitle {
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: #000;
}

.ItemMenuSupDesktop {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
  line-height: 0px;
  text-transform: uppercase;
  margin: 0;
}

.ItemMenuTitleDesktop {
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  margin-bottom: 4px;
  color: #000;
}

.ItemMenuDesktop {
  font-family: Atype-Regular;
  padding: 27px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  cursor: pointer;
}

.TopBar {
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 35px;
  padding-right: 35px;
  height: var(--menu-height-desktop);
  display: flex;
  justify-content: space-between;
  align-items: center;
  right: 0;
  background: #eff3fe;
  border-bottom: 1px solid #e2e2e2;
}

.TitleDesktop {
  font-family: Cousine, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  margin-right: 20px;
}

.BlockLogo {
  display: flex;
  align-items: center;
}

.LogoTTP {
  margin-right: 35px;
}

.LogoTTPMobile {
  margin-right: 12px;
}

.BurgerMenu {
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Sections {
  font-family: Cousine, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding-left: 34px;
  padding-bottom: 34px;
}

.SectionsMobile {
  font-family: Cousine, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  padding-left: 24px;
  padding-bottom: 15px;
}

.HrAbout {
  margin-left: 27px;
  margin-right: 27px;
}

.HrAboutMobile {
  margin-left: 20px;
  margin-right: 20px;
}

.FooterMenu {
  position: absolute;
  bottom: 32px;
  display: flex;
  justify-content: space-between;
  left: calc(100% - 530px);
  right: 34px;
  padding-left: 34px;
}

.FooterTitle {
  font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  text-transform: uppercase;
}

.SocialIcons {
  display: flex;
  align-items: center;
  width: 100px;
  justify-content: space-between;
}
