.title {
  font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130%;
  color: #000;
}

.textContainer {
  padding: 0 18px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.narrativeText {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  padding-top: 100px;
  color: var(--black);
}

.narrativeCaption {
  font-family: Cousine, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 34px;
  padding-top: 16px;
  margin: 0;
  color: var(--black);
}

.narrativeCaptionSource {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  margin: 0;
  padding-top: 16px;
  color: var(--black);
}

.intro {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  width: 700px;
  color: var(--black);
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  text-align: center;
  height: calc(100vh - 123px); /* 44px header + 31px title margin + 48px title = 123px */
}

.IntroStrong {
  font-weight: 600;
}

.intro b {
  font-weight: 600;
}

.wall {
  position: relative;
  height: 100vh;
}

.finalCaption {
  font-family: Cousine, monospace;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 41px;
  width: 494px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--black);
}

.finalCaptionSource {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #646464;
}

.part {
  padding-left: 18px;
  margin-top: 10px;
  width: max-content;
}

.section {
  font-family: General Sans Variable;
  font-style: normal;
  font-weight: 450;
  font-size: 13px;
  line-height: 19px;
  text-transform: uppercase;
  margin: 0;
}

.title {
  font-family: Atype-Medium;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  color: #000;
}
